@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400);

.body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.main_img {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: url(https://www.carday.com.tw/main_pic2.png) left center / cover no-repeat;;
}
/*
.nav_bar {
  width: 100vw;
  position: fixed;
  top: 0vh;
  left: 0vw;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  opacity: 0.3;
}

.nav_bar h3 {
  color: #2d2d2d;
  font-weight: 300;
  float: left;
  margin-left: 20px;
}*/

section {
  position: relative;
  width: 100%;
  height: 100vh;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
  background: -webkit-linear-gradient(top,rgba(0, 0, 0,0) 0,rgba(0, 0, 0,.5) 80%,rgba(0, 0, 0,.7) 100%);
  background: linear-gradient(to bottom,rgba(0, 0, 0,0) 0,rgba(0, 0, 0,.5) 80%,rgba(0, 0, 0,.7) 100%);
}
section h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font : normal 300 64px/1 'Josefin Sans', sans-serif;
  text-align: center;
  white-space: nowrap;
}
#section01 { background: url(https://www.nxworld.net/example/css-scroll-down-button/bg01.jpg) center center / cover no-repeat;}
.demo a {
  width: 10vw;
  position: absolute;
  bottom: 20px;
  left: 45vw;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font-size: 20px;
/*  font : normal 400 20px/1 'Josefin Sans', sans-serif;*/
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
}
.demo a:hover {
  opacity: .5;
}



#section01 a {
  padding-top: 60px;
}
#section01 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

.about_us_holder {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
/*  gap: 30px;*/
  justify-content: center;
  padding: 10vh 0;
}

.title {
  color: rgb(0, 172, 235);
  font-size: 40px;
/*  font : normal 500 48px 'Josefin Sans', sans-serif;*/
}

.content {
  margin: 30px 20px;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 400;
/*  font : normal 400 20px/1 'Josefin Sans', sans-serif;*/
  line-height: 25px;
  text-align: left;
}

.center {
  font-size: 20px;
  text-align: center;
}

.center_title {
  font-size: 25px;
  text-align: center;
  font-weight: 500;
}

.background_grey {
  background-color: #f1f1f1;
}

.news_card_holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5vw;
  justify-content: center;
  padding: 7vh 10vw;
}

.news_card_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20vw;
}

.news_card_title h3 {
  color: rgb(0, 172, 235);
  font-size: 20pt;
}

.news_card {
  display: flex;
  flex-direction: column;
/*  align-items: flex-start;*/
  width: 20vw;
  min-width: 250px;
  border: 0.5px solid #f1f1f1;
  box-shadow: 10px 5px 5px #d1d1d1;
  background-color: white;
}

.news_card h4 {
  margin: 20px;
  color: rgb(0, 172, 235);
  font-weight: 600;
}

.news_card h5 {
  margin: 0 20px 20px;
}

.card_holder {
  width: 100vw; 
  overflow: hidden;

}

.position_absolute {
  position: fixed;
  bottom: 50vh;
  left: 0vw;
}

.footer {
  background-color: #f1f1f1;
  height: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
}

.footer p {
  min-width: 200px;
  font-size: 10pt;
  color: #2d2d2d;
  margin: 0;
  padding: 0;
}

.background_blue h3 {
/*  text-align: left;*/
  color: rgb(0, 172, 235);
  font-size: 20pt;
  line-height: 10vh;
  height: 10vh;
  margin: 0;
  padding: 0;
/*  padding-left: 10vw;*/
}

.gm-ui-hover-effect {
  display: none !important;
}

.cute-button {
    background-color: rgb(0, 172, 235);
    color: rgb(255, 255, 255);
    margin-top: 20px;
    padding: 10px 20px;   /* Adjust as needed for size */
    border-radius: 20px;  /* Rounded corners */
    border: none;
    cursor: pointer;
    font-size: 16px;      /* Adjust for your preference */
    transition: 0.3s;     /* Smooth transition for hover effect */

    /* Making the text bold and center-aligned */
    font-weight: bold;
    text-align: center;

    /* Removing default button styles */
    outline: none;
    box-shadow: none;
}

.cute-button:hover {
    /* Slightly darker background on hover for interaction */
    background-color: rgb(0, 152, 215);
}

.cute-button:active {
    /* Slightly offset when button is pressed for a tactile feel */
    transform: translateY(2px);
}


.custom-link {
    color: rgb(0, 172, 235);
    text-decoration: none; /* Removes the default underline */
    transition: 0.3s;      /* Adds a smooth transition for hover effect */

    /* Optional: Font styles, you can adjust or remove them */
    font-weight: bold;
}

.custom-link:hover {
    color: rgb(0, 152, 215); /* Slightly darker color on hover for interaction */
    text-decoration: underline; /* Underline on hover, you can remove if not desired */
}

.custom-link:active {
    color: rgb(0, 132, 195); /* Even darker color when link is clicked */
}




